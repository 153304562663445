import {imageUrl} from 'common/sanity';
import {breakpoint} from 'common/theme';
import {SanityDividerData} from 'common/types';
import {FC} from 'react';
import ProgressiveImage from 'react-progressive-image';
import styled from 'styled-components';
// @ts-ignore
import colorAlpha from 'color-alpha';

const Wrapper = styled.section`
  position: relative;
  margin-top: ${({theme}) => theme.spacing(2)}px;
  margin-bottom: ${({theme}) => theme.spacing(2)}px;
  ${breakpoint.size('md')`
    margin-top: ${({theme}) => theme.spacing(3)}px;
    margin-bottom: ${({theme}) => theme.spacing(3)}px;
  `};
`;

const Content = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding: ${({theme}) => theme.spacing(2)}px ${({theme}) => theme.spacing(1)}px;
  ${breakpoint.size('sm')`
    padding: ${({theme}) => theme.spacing(2)}px;
  `}
  ${breakpoint.size('md')`
    padding: ${({theme}) => theme.spacing(3)}px ${({theme}) => theme.spacing(2)}px;
  `}
  ${breakpoint.size('lg')`
    padding: ${({theme}) => theme.spacing(4)}px ${({theme}) => theme.spacing(2)}px;
  `}
`;

const Overlay = styled.div`
  width: 100%;
  position: relative;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.2);
`;

interface ImageProp {
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
}

const Image = styled.div<{
  image: ImageProp;
  background: string;
}>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-image: ${({image}) => image && `url(${image.xs})`};
  ${breakpoint.size('sm')`
    background-image: ${({image}: {image: ImageProp}) => image && `url(${image.sm})`};
  `}
  ${breakpoint.size('md')`
    background-image: ${({image}: {image: ImageProp}) => image && `url(${image.md})`};
  `}
  ${breakpoint.size('lg')`
    background-image: ${({image}: {image: ImageProp}) => image && `url(${image.lg})`};
  `}
  ${breakpoint.size('xl')`
    background-image: ${({image}: {image: ImageProp}) => image && `url(${image.xl})`};
  `}
  background-attachment: fixed;
  @supports (-webkit-touch-callout: none) {
    background-attachment: scroll;
  }
  @supports not (-webkit-touch-callout: none) {
    background-attachment: fixed;
  }
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: ${({background}) => background};
`;

const Title = styled.h2`
  margin: 0;
  color: ${({theme}) => theme.colors.white};
  font-size: ${({theme}) => theme.fontSize(1.75)}px;
  ${breakpoint.size('sm')`
    font-size: ${({theme}) => theme.fontSize(2.25)}px;
  `}
  ${breakpoint.size('md')`
    font-size: ${({theme}) => theme.fontSize(3.5)}px;
  `}
`;

const SubTitle = styled.div`
  margin: 0;
  color: ${({theme}) => theme.colors.white};
  font-weight: 600;
  font-size: ${({theme}) => theme.fontSize(1)}px;
  ${breakpoint.size('sm')`
    font-size: ${({theme}) => theme.fontSize(1.1)}px;
  `}
  ${breakpoint.size('md')`
    font-size: ${({theme}) => theme.fontSize(1.35)}px;
  `}
`;

const Divider: FC<SanityDividerData> = props => {
  return (
    <Wrapper>
      <Overlay>
        <Content>
          <Title>{props.title}</Title>
          <SubTitle>{props.subTitle}</SubTitle>
        </Content>
      </Overlay>
      <ProgressiveImage
        src={
          imageUrl(props.image)
            .width(1600)
            .auto('format')
            .quality(80)
            .url() || ''
        }
        placeholder={props.image.lqip || ''}>
        {(src: string) => (
          <Image
            background={colorAlpha(props.image.dominant.background, 0.4)}
            image={{
              xs: src.replace('w=1600', 'w=800'),
              sm: src.replace('w=1600', 'w=1000'),
              md: src.replace('w=1600', 'w=1200'),
              lg: src.replace('w=1600', 'w=1400'),
              xl: src.replace('w=1600', 'w=1600'),
            }}
          />
        )}
      </ProgressiveImage>
    </Wrapper>
  );
};

export default Divider;
